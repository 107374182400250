// IMAGE系
const BACKGROUND_IMAGE_PATH = "/background";
export const HEADER_IN_BOARD_BG_PATH = BACKGROUND_IMAGE_PATH + "/brand-header-in-board.svg";
export const AUTH_LEFT_BG_PATH = BACKGROUND_IMAGE_PATH + "/auth-left-bg.png";
const ASSETS_PATH = "/assets";
export const NO_IMAGE_PATH = ASSETS_PATH + "/no_img.png";

// ページ系
export const LOGIN_PATH = "/";
export const DASHBOARD_PATH = "/dashboard";

export const ORG_PATH = "/organization";
export const ORG__COMPANY_PATH = `${ORG_PATH}/company`;
export const ORG__WORKSPACE_PATH = `${ORG_PATH}/workspace`;
export const ORG__STAFF_PATH = `${ORG_PATH}/staff`;
export const ORG__PROJECT_PATH = `${ORG_PATH}/project`;

export const TASK_INDEX_PATH = "/task";
export const ALL_TASK_PATH = `${TASK_INDEX_PATH}/all`; // パス情報に、typeを含む
export const MY_TASK_PATH = `${TASK_INDEX_PATH}/me`; // パス情報に、typeを含む
export const GROUP_TASK_PATH = `${TASK_INDEX_PATH}/group`; // パス情報に、idとtypeを含む
export const MGMT_TASK_PATH = `${TASK_INDEX_PATH}/management`; // パス情報に、typeを含む
export const PUBLIC_TASK_PATH = `${TASK_INDEX_PATH}/public`; // パス情報に、typeを含む
export const PROJECT_TASK_PATH = `${TASK_INDEX_PATH}/project`; // パス情報に、idとtypeを含む

export const ORG_INDEX_PATH = "/organization";
export const ORG_STAFF_PATH = `${ORG_INDEX_PATH}/staff`; // パス情報に、typeを含む
export const ORG_DEFINE_PATH = `${ORG_INDEX_PATH}/definition`;
export const ORG_PROJECT_PATH = `${ORG_INDEX_PATH}/project`;
export const COM_INDEX_PATH = "/communication";
export const MYPAGE_INDEX_PATH = `/mypage`;
export const ORG__ORG_DEFINE_PATH = `${ORG_PATH}/definition`;
export const ORG__ORG_DEFINE_SUMMARY_PATH = `${ORG__ORG_DEFINE_PATH}/summary`;
export const ORG__ORG_DEFINE_GROUP_PATH = `${ORG__ORG_DEFINE_PATH}/group`;
export const ORG__ORG_DEFINE_LAYER_PATH = `${ORG__ORG_DEFINE_PATH}/layer`;
export const ORG__ORG_DEFINE_POSITION_PATH = `${ORG__ORG_DEFINE_PATH}/position`;

export const TASK_PATH = "/task";
export const MEETING_PATH = "/communication/meeting";

export const ACCOUNT_PATH = "/account";
export const ACCOUNT__ADMIN_PATH = `${ACCOUNT_PATH}/admin`;
export const ACCOUNT__USERS_PATH = `${ACCOUNT_PATH}/user`;

export const TPL_PATH = "/tpl";
export const TPL__GROUP_PATH = `${TPL_PATH}/group`;
export const TPL__USE_CASE_PATH = `${TPL_PATH}/use-case`;
export const TPL__TASK_PATH = `${TPL_PATH}/task`;
export const TPL__MEETING_PATH = `${TPL_PATH}/meeting`;

export const LOG_PATH = "/log";
export const LOG__ADMIN_ACTIVITY_PATH = `${LOG_PATH}/admin-activity`;
export const LOG__ADMIN_TASK_ACTION_PATH = `${LOG_PATH}/admin-task-action`;
export const LOG__USER_ACTIVITY_PATH = `${LOG_PATH}/user-activity`;
export const LOG__USER_TASK_ACTION_PATH = `${LOG_PATH}/user-task-action`;
export const LOG__INVOICE_PATH = `${LOG_PATH}/invoice`;
export const SETTING_PATH = "/setting";

export const PARTNER_PATH = "/partner";
export const PARTNER__LIST_PATH = `${PARTNER_PATH}/partner`;