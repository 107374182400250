import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class PartnerApi extends BaseApi {
  async getPartnerList(
    params: APIPaths["/admin-dash/partner/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/partner/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/partner/list", { params })).data;
  }

  async postPartnerNew(
    requestBody: APIPaths["/admin-dash/partner/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/partner/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/partner/new`, requestBody)).data;
  }

  async patchPartnerById(
    id: string,
    requestBody: APIPaths["/admin-dash/partner/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/partner/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/partner/${id}`, requestBody)).data;
  }

  async deletePartnerById(
    id: string,
  ): Promise<APIPaths["/admin-dash/partner/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/partner/${id}`)).data;
  }
}
